import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import SEO from '../../components/seo'
import IconPdf from '../../images/icon-pdf.inline.svg'

const ResourcesPage = () => {
  const data = useStaticQuery(graphql`
    query ResourcesDocuments {
      documents: markdownRemark( fields: { parent: { relativeDirectory: { eq: "resources"}, name: {eq: "documents"}}}) { 
        frontmatter {
          documents {
            filename
            title
          }
        }
      }
    }
  `)

  const { documents } = data

  return (
    <>
      <SEO title="Resources" />

      <section className="products-content resources-documents">
        <h1>Documents</h1>

        {
          documents.frontmatter.documents.map(document => (
            <div className="resources-documents__document-item">
              <IconPdf />
              <div><a href={document.filename}>{document.title}</a></div>
            </div>
          ))
        }
      </section>

    </>
  )
}

export default ResourcesPage